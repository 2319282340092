<template>
  <PageListLayout
    icon="mdi-file"
    title="Chứng từ"
    subTitle="Danh sách chứng từ"
    @reset-filter="resetFilter"
  >
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="mt-4">
        <v-menu
          v-model="showMenuDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formSearch.ngay"
              append-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              label="Ngày"
              hide-details
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            locale="vi"
            v-model="formSearch.ngay"
            range
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.khu_vuc_id"
          :items="areaList"
          label="Khu vực"
          outlined
          dense
          hide-details
          item-text="ten"
          item-value="id"
          clearable
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.khach_hang_id"
          :items="customerList"
          label="Khách hàng"
          outlined
          dense
          hide-details
          item-text="ten"
          item-value="id"
          clearable
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.ten"
                  v-html="data.item.ten"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
      showExpand
      singleExpand
      :expanded="expanded"
      @expanded="(val) => getDetailLicenseApi(val)"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        <div>{{ formatDate(item.created_at) }}</div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <a :key="item.created_at" href="#" @click="exportLicense(item)">
          {{
            loadingExport == item.id
              ? "Đang tải file chứng từ ..."
              : "Xuất chứng từ chất thải"
          }}</a
        >
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div class="pa-4 center" :key="item.id">
            <CustomTable
              hide-default-footer
              :loading="loadingExpanded"
              :headers="headerDetail"
              :items="dataTableDetail"
              :page="-1"
            >
            </CustomTable>
          </div>
        </td>
      </template>
    </CustomTable>
    <create-edit ref="form"></create-edit>
  </PageListLayout>
</template>
<script>
import {
  getDetailLicenseApi,
  getCustomerApi,
  getAreaApi,
  downloadLicense,
  getLicenseApi,
} from "@/api/chungtu";
import { debounce } from "lodash";
import CreateEdit from "./create-edit";
// import axios from "axios";
import { saveAs } from "file-saver";

export default {
  components: { CreateEdit },
  data(vm) {
    return {
      formSearch: {
        ngay: vm.initDate(),
      },
      showMenuDate: false,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      areaList: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      customerList: [],
      loadingExport: -1,
      headers: [
        {
          text: "Ngày",
          align: "start",
          sortable: false,
          value: "ngay",
        },
        {
          text: "Khu vực",
          align: "start",
          sortable: false,
          value: "khu_vuc",
        },
        {
          text: "Mã khách hàng",
          align: "start",
          sortable: false,
          value: "ma",
        },
        {
          text: "Tên khách hàng",
          align: "start",
          sortable: false,
          value: "ten",
        },
        {
          text: "Tổng khối lượng",
          align: "start",
          sortable: false,
          value: "tong_khoi_luong",
        },
        {
          text: "Tổng khối lượng cân(kg)",
          align: "start",
          sortable: false,
          value: "tong_khoi_luong_thuc_te",
        },
        {
          text: "Hành động",
          align: "start",
          sortable: false,
          value: "action",
        },
      ],
      current_expant: {},
      expanded: [],
      loadingExpanded: false,
      statusList: [
        { value: 1, name: "Mới tạo" },
        { value: 2, name: "Đã xử lý" },
      ],
      headerDetail: [
        {
          text: "Tên CTNH",
          align: "start",
          sortable: false,
          value: "ten_hang_hoa",
        },
        {
          text: "Mã CTNH",
          align: "start",
          sortable: false,
          value: "ma_hang_hoa",
        },
        {
          text: "Trạng thái tồn tại",
          align: "start",
          sortable: false,
          value: "ten_trang_thai_ton_tai",
        },
        {
          text: "Khối lượng",
          align: "start",
          sortable: false,
          value: "ma_hang_hoa",
        },
        {
          text: "Phương pháp xử lý",
          align: "start",
          sortable: false,
          value: "phuong_phap_xu_ly",
        },
      ],
      dataTableDetail: [],
    };
  },
  computed: {},
  created() {
    this.getApi();
    this.getAllList();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.page = 1;
        this.getApi();
      }, 300),
    },
  },
  methods: {
    initDate() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return [`${year}-${month}-1`, `${year}-${month}-${day}`];
    },
    resetFilter() {
      this.search = "";
      this.formSearch.ngay = [];
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    async getDetailLicenseApi(item) {
      if (item.length > 0) {
        item = item[0];
        try {
          this.loadingExpanded = true;
          this.dataTableDetail = [];
          const res = await getDetailLicenseApi({
            ngay: item.ngay,
            khach_hang_id: item.khach_hang_id,
          });
          this.dataTableDetail = res.data;
        } finally {
          this.loadingExpanded = false;
        }
      }
    },
    async getApi() {
      this.loading = true;
      let params = { ...this.formSearch };
      if (params.ngay) {
        params.ngay = [...params.ngay];
        if (params.ngay.length == 1) {
          params.ngay.push(params.ngay[0]);
        } else if (params.ngay.length == 2) {
          if (!params.ngay[1]) {
            params.ngay[1] == params.ngay[2];
          }
        }
      }
      const res = await getLicenseApi(params);
      this.tableData = res.data;
      this.pageCount = this.tableData.length;
      // console.log(res);
      this.loading = false;
    },
    async exportLicense(item) {
      try {
        this.loadingExport = item.id;
        const res = await downloadLicense({
          khach_hang_id: item.khach_hang_id,
          ngay: item.ngay,
        });
        saveAs(new Blob([res]), "CTNH.xlsx");
      } finally {
        this.loadingExport = -1;
      }
    },
    async getAllList() {
      let res = await getCustomerApi();
      this.customerList = res.result;
      res = await getAreaApi();
      this.areaList = res.result;
    },
    changePage(val) {
      this.page = val;
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
  },
};
</script>
<style scoped>
.style_auto_complate {
  max-width: 200px;
}
</style>
