<template>
  <v-dialog v-model="show" persistent width="900">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT CHỨNG TỪ" : "THÊM CHỨNG TỪ"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Mã hợp đồng</div>
              <v-autocomplete
                prepend-inner-icon="mdi-map-marker"
                v-model="form.contract_id"
                :items="contractsList"
                outlined
                dense
                item-text="contract_code"
                item-value="id"
                @change="onChange($event)"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Khách hàng</div>
              <v-autocomplete
                prepend-inner-icon="mdi-map-marker"
                v-model="form.customer_id"
                :items="customerList"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>

            <v-col xs="6" sm="6" md="6">
              <!-- :rules="formValidate.name" -->
              <div class="label-form">Tổng khối lượng</div>
              <v-text-field
                placeholder="Tổng khối lượng"
                outlined
                dense
                prepend-inner-icon="mdi-account"
                v-model="form.tong_khoi_luong"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col xs="6" sm="6" md="6">
              <!-- :rules="formValidate.name" -->
              <div class="label-form">Tổng khối lượng cân</div>
              <v-text-field
                placeholder="Tổng khối lượng cân"
                outlined
                dense
                prepend-inner-icon="mdi-account"
                v-model="form.tong_khoi_luong_can"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <div class="label-form">Tên tin đính kèm</div>
              <UploadFile
                :reference_id="form.id"
                :editing="false"
                v-model="form.files"
                multiple
              />
            </v-col>

            <v-col cols="12" class="zero-vertical">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import { editCustomers } from "@/api/khachhang";
import { addLicense, uploadLicense } from "@/api/chungtu";
import UploadFile from "@/components/upload/UploadFile";
import JsonToFromData from "@/utils/JsonToFormData.js";
export default {
  props: ["contractsList", "customerList"],
  components: { UploadFile },
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      id: null,
      contract_id: null,
      contract_code: null,
      files: [],
      description: "",
    },
    nameRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    misaCodeRules: [(v) => !!v || "Mã misa không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã khách hàng không thể bỏ trống"],
    emailRules: [
      (v) => !!v || "E-Mail không thể bỏ trống",
      (v) => /.+@.+\..+/.test(v) || "E-mail không hợp lệ",
    ],
    fileList: [],
  }),
  computed: {},
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        id: null,
        contract_id: null,
        contract_code: null,
        files: [],
        description: "",
      };
      this.fileList = [];
      if (this.$refs["files"] !== undefined) {
        this.$refs["files"].value = null;
      }
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data = JsonToFromData(data);
          await addLicense(data);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Thêm chứng từ thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });

          this.$refs["files"].value = null;
          this.clearData();
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await uploadLicense(this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    clearData() {
      this.fileList = [];
      this.form = {
        contract_id: null,
        contract_code: null,
        files: [],
      };
      this.ProductData = [];
      this.selectedData = [];
    },
    handleUpload() {
      this.fileList = this.$refs.files.files;
    },
    onChange(e) {
      let find = this.customerList.find((item) => item.id === e);

      console.log({find, e, customerList: this.customerList})
      if (find) {
        this.form.customer_id = find.id;
      }
    },
  },
};
</script>
