import request from "../utils/request";

export function getAllLicense(params) {
  return request({
    url: "license",
    method: "get",
    params,
  });
}

export function addLicense(data) {
  return request({
    url: "license",
    method: "post",
    data,
  });
}

export function uploadLicense(data) {
  return request({
    url: "uploadlicense",
    method: "post",
    data,
  });
}

export function downloadLicense(params) {
  return request({
    url: "baocaochungtu-file",
    method: "get",
    params,
    responseType: "blob",
  });
}

export function deleteLicense(data) {
  return request({
    url: "/license",
    method: "delete",
    data,
    responseType: "blob",
  });
}

export function getLicenseApi(params = {}) {
  return request({
    url: "/baocaochungtu",
    params,
  });
}
export function getDetailLicenseApi(params = {}) {
  return request({
    url: "/chitietbaocaochungtu",
    params,
  });
}
export function getCustomerApi(params = {}) {
  return request({
    url: "/chungtu-khachhang",
    params,
  });
}
export function getAreaApi(params = {}) {
  return request({
    url: "/chungtu-khuvuc",
    params,
  });
}
